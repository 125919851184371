<template>
    <v-container class="pa-0 fill-height">
         <v-card class="ma-0 rounded-0 fill-height" color="error darken-4" dark >
       <v-card-title>
        
        <v-icon left x-large color="white">
            mdi-alert-circle
        </v-icon>
        Suscripción Expirada</v-card-title>

        <v-card-text style="font-size: 18px;">
         Tu suscripción ha expirado. Renueva tu suscripción para continuar disfrutando de nuestros servicios.
        </v-card-text>

          <!--Card payment button-->
           <v-btn rounded
    class="mx-auto mb-6 mt-4"
    color="primary"
    dark
    x-large
    block
    @click="$router.push('/pago')"
    >
    <v-icon left color="white">
        mdi-credit-card
    </v-icon>
    Pagar con credito/debito
    </v-btn> 

            <v-card-text style="font-size: 18px;">
            Ponte en contacto para renovar tu suscripción. Recuerda que pasados 15 dias desde el fin de tu suscripción, tu cuenta será dada de baja.

            </v-card-text>

              <!--Whatsapp contact v-btn -->
    <v-btn rounded
    class="mx-auto"
    color="green"
    dark
    x-large
    block
    href="https://wa.me/+59898996900?text=Hola!%20Quiero%20renovar%20mi%20suscripción"
    target="_blank"
    >
    <v-icon left color="white">
        mdi-whatsapp
    </v-icon>
    Contacto por Whatsapp
    </v-btn>


            
   
    </v-card>


      
  

    </v-container>
   

</template>

<script>
export default {
};
</script>

<style scoped>
.v-card {
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}
</style>
